import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import Container from 'react-bootstrap/Container';
import config from '../../../utilities/config.js';
import AdvSch from './AdvancedSeachVars';
import { Table, Row, Col } from 'react-bootstrap';

var Userlist = null;
var Buildingslist = null;
var info = null;
var orgname = null;

class PropResultsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadedUsers: false,
            loadedBuildings: false,
            loadedOrg: false,
            loadedProp: false,
        };
    }
    componentDidMount() {
        this.getInfo()
    }
    /* OrgRole(role) {
        switch (role) {
            case 4:
                return ("Owner");
            case 3:
                return ("Admin");
            case 2:
                return ("Associate");
            case 1:
                return ("Member");
            default:
                return ("None");
        }

    } */

    //get information based on filled out fields
    getInfo() {

        //Get Info
        let url = `${config.api}property/${AdvSch.Prop.id}`;
        let token = localStorage.getItem("mlToken");

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        )
            .then((response) => {
                this.setState({
                    loadedProp: true,
                });
                //console.log("stuff", response)
                const data = response["data"];
                info = []
                orgname = data.organizationID
                info.push(
                    <tr key={data.id}>
                        <td>{"Property: " + data.label}</td>
                    </tr>);
                    url = `${config.api}organization/${orgname}`;
                    token = localStorage.getItem("mlToken");

                    axios.get(
                        url,
                        {
                            headers: { Authorization: `Bearer ${token}` }
                        }
                    )
                        .then((response) => {
                            this.setState({
                                loadedOrg: true,
                            });
                            //console.log("stuff", response)
                            const data = response["data"];
                            info.push(
                                <tr key={data.id}>
                                    <td>{"Organization: " + data.label}</td>
                                </tr>);

                            return info;
                        },
                            (error) => {
                                //console.log(error);
                            }
                        );
                


            },
                (error) => {
                    //console.log(error);
                }
            );



    }

    getUsers() {
        if (this.state.loadedUsers) {
            return Userlist;
        }
        else {
            //Get all Users
            let url = `${config.api}property/${AdvSch.Prop.id}/users`;
            let token = localStorage.getItem("mlToken");

            axios.get(
                url,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            )
                .then((response) => {
                    //console.log(response)
                    const data = Object.values(response["data"]);
                    //console.log(data)
                    Userlist = []
                    data.map((item) => {
                        Userlist.push(
                            <tr key={item.id}>
                                <td>{item.firstName} {item.lastName}</td>
                                <td>{item.email}</td>
                                {/* <td>{this.OrgRole(item.organizationRoleID)}</td> */}
                            </tr>);
                        return item;
                    });
                    this.setState({
                        loadedUsers: true,
                    });
                    return Userlist;
                },
                    (error) => {
                        //console.log(error);
                    }
                );
        }
    }



    getBuilding() {
        if (this.state.loadedBuildings) {
            return Buildingslist;
        }
        else {
            //Get all Orgs
            let url = `${config.api}property/${AdvSch.Prop.id}/buildings`;
            let token = localStorage.getItem("mlToken");

            axios.get(
                url,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            )
                .then((response) => {
                    //console.log(response)
                    const data = response["data"];
                    Buildingslist = []
                    data.map((item) => {
                        Buildingslist.push(
                            <tr key={item.id}>
                                <td>{item.label}</td>
                                <td>{item.address}</td>
                            </tr>);
                        return item;
                    });
                    this.setState({
                        loadedBuildings: true,
                    });
                    return Buildingslist;
                },
                    (error) => {
                        //console.log(error);
                    }
                );
        }
    }


    render() {
        Swal.close();
        return (<Container>

            <h1>
                <Table>
                    <tbody>
                        {info}
                    </tbody>
                </Table>
            </h1>

            <Row>
                <Col>
                    <h2>Users:</h2>
                    <Table>
                        <thead>
                            <tr>
                                <th>Name:</th>
                                <th>Email:</th>
                                {/* <th>Role:</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {this.getUsers()}
                        </tbody>
                    </Table>
                </Col>
                <Col>
                    <h2>Buildings:</h2>
                    <Table>
                        <thead>
                            <tr>
                                <th>Name:</th>
                                <th>Address:</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.getBuilding()}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>)
    }

}
export default withRouter(PropResultsView);