import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import config from '../../utilities/config.js';
import Sidebar from '../../components/Sidebar/Sidebar.js';
import axios from 'axios';
import LoadingView from '../LoadingView/LoadingView';
import Swal from 'sweetalert2';
import moment from 'moment';
import './RoomView.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import GraphBlock from '../../components/Block/DashboardBlocks/GraphBlock.js';
import ExpandedGraphBlock from '../../components/Block/DashboardBlocks/ExpandedGraphBlock.js';
import GallonsUsed from '../../components/Block/DashboardBlocks/GallonsUsed.js';
import GallonsLeaked from '../../components/Block/DashboardBlocks/GallonsLeaked.js';
import Flushes from '../../components/Block/WaterUseBlocks/Flushes';
import AlertsBlock from '../../components/Block/DashboardBlocks/AlertsBlock';
import AlertHistoryBlock from '../../components/Alert/AlertBlock/AlertHistoryBlock.js';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import {Redirect} from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import TempGraphBlock from '../../components/Block/DashboardBlocks/TempGraphBlock.js';
import HumidGraphBlock from '../../components/Block/DashboardBlocks/HumidGraphBlock.js';
import DataBlock from '../../components/Block/DashboardBlocks/DataBlock.js';



class RoomView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            loaded: false,
            roomSelected: false,
            showGraphModal: false,
            showModal: false,
            roomSearch: null,
            selectedRoom: "",
            leakData: null,
            totalGallons: 0,
            gallonsCost: 0,
            totalLeaked: 0,
            leakCost: 0,
            totalFlushes: 0,
            roomData: [],
            dateKeys: [],
            buildings: [],
            buildingLocations: null,
            tempData: null,
            THdata: null,
            THdataKeys: null,
            humData: null,
            THalerts: 0,
            degreeScale: 'F'
        };

        const { roomName = false } = queryString.parse(props.location.search);
        if (roomName) {
            this.state.roomSearch = roomName;
        }

        const { building = false } = queryString.parse(props.location.search);
        if (building) {
            this.state.building = building;
        }
    }

    componentDidMount() {
        gtag('config', config.gtag, {
            page_title: 'Room_view',
          })
        gtag('event','page_view')
        document.body.style.backgroundColor = "#EBF2F8";
        this.getUserBuildings();
        window.addEventListener('resize', () => this.setState({ windowWidth: window.innerWidth }));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => this.setState({ windowWidth: window.innerWidth }));
    }

    componentDidUpdate(prevProps, prevState) {
        const newLocations = this.state.buildingLocations && !prevState.buildingLocations;

        // Auto-search for room if page URL had roomName query parameter, only trigger after getUserBuildings has finished loading.
        if (this.state.roomSearch && newLocations) {
            this.handleRoomSearchButton(null);
        }
    }

    getUserBuildings() {
        this.setState({
            roomSelected: false
        })

        let url = `${config.api}advanced-building-functions/user/${config.userID}`;
        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
        )
            .then((response) => {
                let buildings = response.data;
                if (buildings.length != 0) {
                    this.setState({
                        buildings: buildings
                    })


                    if (config.selectedBuilding == 0) {
                        Swal.close();
                        Swal.fire({
                            title: "You must have a building selected to view a specific Location.",
                            icon: 'error'
                        })
                            .then(() => {
                                window.location.href = "/";

                                //props.history.push('/')
                                // this.setState({
                                //     loaded: true,
                                //     buildingLocations: []
                                // });
                            });
                    }
                    else {
                        buildings.forEach((building) => {
                            if (building.id == config.selectedBuilding) {
                                this.getBuildingLocations(building)
                            }
                        });
                    }
                }
                else {
                    Swal.close();
                    Swal.fire({
                        title: "It Looks Like You Aren't Assigned Any Buildings.",
                        icon: 'error'
                    });
                }
            });
    }

    getBuildingLocations(selectedBuilding) {
        //console.log(selectedBuilding);
        //Get all locations in a building
        let buildingID = selectedBuilding["id"];
        let url = `${config.api}buildings/${buildingID}/locations`;
        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
        )
            .then((response) => {
                const data = response["data"];
                var locationsByID = [];
                var locations = data.map((item) => {
                    item.key=item.toiletID+item.userID
                    locationsByID[item.locationID] = item;
                    return (item);
                });
                locations.sort((a, b) => a.locationName - b.locationName);
                this.setState({
                    buildingLocations: locations,
                    locationsByID: locationsByID,
                    selectedBuilding: selectedBuilding,
                    loaded: true
                });
            },
                (error) => {
                    Swal.close();
                    Swal.fire({
                        title: "Error Getting Building Locations",
                        text: error,
                        icon: 'error'
                    });
                }
            );
    }

    showRoomDetails(location) {
        const endDate = moment().format("YYYY-MM-DD");
        const startDate = moment().subtract(config.dateRange, 'days').format("YYYY-MM-DD");
        this.getLocationMetrics(location, startDate, endDate);
        this.getBuildingInformationTempHumid();
    }

    getLocationMetrics(location, startDate, endDate) {
        //Get location metrics
        let url = `${config.api}metrics/locations/${location.locationID}?startDate=${config.startDate}&endDate=${config.endDate}`;
        //console.log(url);
        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
        )
            .then((response) => {
                let roomData = [];
                let dateKeys = [];

                //console.log(response);
                //console.log(response["data"]);

                const data = response["data"][0];
                roomData = data["dayBreakDown"];
                dateKeys = roomData.map((day) => {
                    return (day.dDate);
                });
                this.setState({
                    roomData: roomData,
                    dateKeys: dateKeys,
                    totalGallons: data["gallonsT"],
                    gallonsCost: data["costTT"],
                    totalLeaked: data["gallonsLT"],
                    leakCost: data["costTLT"],
                    totalFlushes: data["totalFlushesT"],
                });

                this.getAlertInformation(location, startDate, endDate);
            },
                (error) => {
                    Swal.close();
                    Swal.fire({
                        title: "Error Getting Location Metrics",
                        text: error,
                        icon: 'error'
                    });
                }
            );
    }

    getAlertInformation(location, startDate, endDate) {
        //console.log(location)
        let url = `${config.api}metrics/portalStats/building/${config.selectedBuilding}?startDate=${startDate}&endDate=${endDate}`;

        let sCall = Date.now();
        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
        )

            .then((response2) => {
                let eCall = Date.now();



                let lData = response2["data"];
                let keys = Object.keys(lData);
                let fKey = keys[0];
                let iData = lData[fKey]["statsByDate"];
                let rData = {};

                let iDataKeys = Object.keys(iData);
                for (let i = 0; i < iDataKeys.length; i++) {
                    let curKey = iDataKeys[i]
                    let curData = iData[curKey];

                    let date = curKey;
                    let gallons = curData["gallonsUsed"];
                    let flushes = curData["flushes"];
                    let gallonsWasted = curData["gallonsWasted"];
                    let leakCost = curData["leakCost"];
                    rData[date] = curData;

                }
                let leakData = {}
                let leakTotal = 0
                leakData[fKey] = lData[fKey]["statsForProblems"];
                leakTotal = Object.keys(leakData[fKey]).length;

                let flushData = {};
                flushData[fKey] = lData[fKey]["locationStatus"];

                //==================================================================================================================================================
                this.setState({
                    leakData: leakData
                });

                const dataKeys = Object.keys(leakData);
                //console.log(leakData);

                //Recreate the roomUsage object with the list of rooms/totals as a map
                let alertHistoryMap = {};
                let alertCount = 0;

                for (let i = 0; i < dataKeys.length; i++) {
                    const problems = leakData[dataKeys[i]];
                    const problemKeys = Object.keys(leakData[dataKeys[i]]);
                    for (let k = 0; k < problemKeys.length; k++) {
                        const curAlert = problems[problemKeys[k]]
                        if (curAlert.gallons != 0) {
                            const newAlert = {
                                "date": curAlert.date,
                                "building": curAlert.building,
                                "locationLabel": curAlert.locationLabel,
                                "gallons": curAlert.gallons,
                                "buildingLabel": curAlert.buildingLabel,
                                "severityNum": curAlert.severityNum,
                                "locationID": curAlert.locationID,
                                "alertCount": 0
                            };
                            if (newAlert.locationLabel == location.locationName) {
                                alertCount++;
                                //console.log(alertCount);
                                if (alertHistoryMap[newAlert.locationLabel] == null) { //No alerts added yet
                                    alertHistoryMap[newAlert.locationLabel] = [newAlert];
                                }
                                else {
                                    alertHistoryMap[newAlert.locationLabel].push(newAlert)
                                }
                            }
                        }
                    }
                }
                //=============================================================================================================================================================

                this.setState({
                    alertCount: alertCount,
                    alertHistoryMap: alertHistoryMap,
                    startDate: startDate,
                    endDate: endDate,
                    loaded: true
                });

            },
                (error) => {
                    //console.log(error);
                }
            );
    }

    exportData = (api, token, title) => {
        //console.log(this.state);
        if (!this.state.roomSelected) {
            Swal.fire({
                title: "You must have a location selected to export a specific location's data.",
                icon: 'error'
            })
        }

        if (this.state.loaded && this.state.roomSelected) {
            Swal.fire({
                'title': 'Exporting File ...',
                'timer': 60000,
                'allowOutsideClick': false,
                onBeforeOpen: () => {
                    Swal.showLoading()
                }
            });

            const requestBody = {
                "reportType": "room",
                "data": {
                    "building_title": title,
                    "building_subtitle": "",
                    "start_date": this.state.startDate,
                    "end_date": this.state.endDate,
                    'room_name': this.state.selectedRoom["locationName"],
                    'num_flushes': this.state.totalFlushes,
                    'gallons_used': this.state.totalGallons,
                    'gallons_leaked': this.state.totalLeaked,
                    'gallons_used_cost': this.state.gallonsCost,
                    'gallons_leaked_cost': this.state.leakCost,
                }
            };

            let url = api + `pdf-page-export`;
            let auth = `Bearer ` + token;
            var config = {
                method: 'post',
                url: url,
                headers: {
                    'Authorization': auth,
                    'Content-Type': 'application/json',
                },
                data: requestBody
            };

            axios(config)
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    let data = response["data"];
                    let uploadFile = data["uploadFile"];
                    let url = uploadFile["uploadFile"];
                    Swal.close()
                    Swal.fire({
                        title: 'Report Created',
                        text: 'Clicking the download button will open a new tab or window to download your file.',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#3578bd',
                        confirmButtonText: 'Download'
                    }).then(() => {
                        window.open(url);
                    })
                });

        }
    }

    handleRoomSearchInput = (newValue) => {
        this.setState({
            roomSearch: newValue.locationName
        });
    }

    handleRoomSearchButton = (event) => {
        if (event) {
            event.preventDefault();
        }

        const roomName = this.state.roomSearch;
        if (roomName == null || roomName == "") {
            return;
        }

        const buildingLocations = this.state.buildingLocations;
        let selectedRoom = null;

        if (config.selectedBuilding == 0) {
            Swal.fire({
                title: "You must have a building selected to view a specific room.",
                icon: 'error'
            })
        }
        else if (buildingLocations == null) {
            Swal.fire({
                title: "You must have a building selected to view a specific room.",
                icon: 'error'
            })
        }
        else {
            buildingLocations.forEach((location) => {
                if (location.locationName == roomName) {
                    selectedRoom = location;
                }
            });

            //console.log(roomName);
            //console.log(buildingLocations);
            //console.log(selectedRoom);

            if (selectedRoom != null) {
                this.setState({
                    selectedRoom: selectedRoom,
                    roomSelected: true,
                    loaded: false
                });
                this.showRoomDetails(selectedRoom)
            }
            else {
                Swal.fire({
                    'title': 'Could not find that location'
                });
            }
        }
    }

    showAlertHistory = () => {
        if (!this.state.alertCount > 0) { return }
        const locationID = this.state.selectedRoom.locationName;
        //console.log(locationID);
        //console.log(this.state.alertHistoryMap);
        let alertHistory = this.state.alertHistoryMap[locationID];
        //Grab flushes per day from data
        const dayBreakdown = this.state.roomData;
        let flushesByDay = {};
        dayBreakdown.forEach((day) => {
            flushesByDay[day.dDate] = day.totalFlushes;
        });

        //Combine flush and alert history
        let alerts = alertHistory.map((curAlert) => {
            return (
                {
                    "date": curAlert.date,
                    "building": curAlert.building,
                    "locationLabel": curAlert.locationLabel,
                    "gallons": curAlert.gallons,
                    "buildingLabel": curAlert.buildingLabel,
                    "severityNum": curAlert.severityNum,
                    "locationID": curAlert.locationID,
                    "flushCount": flushesByDay[curAlert.date]
                }
            );
        });

        //Sort alerts with newest first
        alerts.sort((a, b) => (a.date < b.date) ? 1 : -1);

        //Create alert blocks
        let alertHistoryBlocks = alerts.map((alert) => {
            let severityClass = "severity-" + alert.severityNum;
            let severityLabel = "";
            let severityColor = ""
            switch (severityClass) {
                case "severity-5":
                    severityLabel = "Critical"
                    severityColor = "#E73C3E"
                    break;
                case "severity-4":
                    severityLabel = "High"
                    severityColor = "#E73C3E"
                    break;
                case "severity-3":
                    severityLabel = "Medium"
                    severityColor = "#FF8132"
                    break;
                case "severity-2":
                    severityLabel = "Low"
                    severityColor = "#f8de7e"
                    break;
                case "severity-1":
                    severityLabel = "Very Low"
                    severityColor = "#f8de7e"
                    break;
                default:
                    break;
            }
            return (
                <AlertHistoryBlock
                    severityColor={severityColor}
                    problem={alert}
                    flushCount={alert.flushCount}
                />
            )
        })

        this.setState({
            showModal: true,
            alertHistoryBlocks: alertHistoryBlocks
        });
    }

    handleClose = () => {
        this.setState({
            showModal: false
        });
    }

    handleGraphClose = () => {
        this.setState({
            showGraphModal: false
        });
    }

    handleGraphShow = () => {
        //console.log('graph show clicked');
        this.setState({
            showGraphModal: true
        });
    }

    getBuildingInformationTempHumid() {
        let url = `${config.api}metrics/portalStats/building/3?startDate=${config.startDate}&endDate=${config.endDate}&type=humidity`;
        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
        )
            .then((response2) => {
                let lData = response2["data"];
                let keys = Object.keys(lData);
                let fKey = keys[0];
                let iData = lData[fKey]["statsByDate"];
                let TData = [];
                let HData = [];
                // let THdata=[];
                let Idx = 0;

                let THalerts = {}
                let THalertTotal = 0
                THalerts[fKey] = lData[fKey]["statsForProblems"];
                THalertTotal = Object.keys(THalerts[fKey]).length;

                let iDataKeys = Object.keys(iData);
                //console.log(lData);
                //console.log(iData);
                //console.log(iDataKeys);
                for (let i = 0; i < iDataKeys.length; i++) {
                    let curKey = iDataKeys[i];
                    let curData = iData[curKey];
                    for (let x = 0; x < curData.length; x++) {
                        // THdata[Idx]=curData[x];
                        HData[Idx] = curData[x].humidity;
                        TData[Idx++] = curData[x].temperature;
                    }
                }


                this.setState({
                    tempData: TData,
                    humData: HData,
                    THdata: iData,
                    THdataKeys: iDataKeys,
                    THalerts: THalertTotal,
                });


            },
                (error) => {
                    //console.log(error);
                }
            );
    }

    render() {
        if (this.state.roomSelected && this.state.loaded) {

            const roomData = this.state.roomData;
            //console.log(roomData);
            let gallonData = roomData.map((data) => {
                return (data.gallons);
            });
            let leakData = roomData.map((data) => {
                return (data.gallonsL);
            });

            let TData = null;
            let HData = null;
            let AvgTemp = 0;
            let MaxTemp = 0;
            let MinTemp = 999;
            let AvgHum = 0;
            let MaxHum = 0;
            let MinHum = 999;

            if (this.state.tempData != null) {
                TData = this.state.tempData;
                HData = this.state.humData;
                AvgTemp = parseFloat(TData[0]);
                AvgHum = parseFloat(HData[0]);
                for (let x = 0; x < TData.length; x++) {
                    AvgTemp += parseFloat(TData[x]);
                    AvgTemp /= 2;
                    TData[x] > MaxTemp ? MaxTemp = TData[x] : null;
                    TData[x] < MinTemp ? MinTemp = TData[x] : null;
                }

                for (let x = 0; x < HData.length; x++) {
                    AvgHum += parseFloat(HData[x]);
                    AvgHum /= 2;
                    HData[x] > MaxHum ? MaxHum = HData[x] : null;
                    HData[x] < MinHum ? MinHum = HData[x] : null;
                }
            }
            Swal.close();
            return (
                <div id="pageOuterContainer" className="pageOuterContainer" >

                    {this.state.windowWidth > 650 &&
                        <Sidebar
                            pageWrapId={"pageWrap"}
                            outerContainerId={"pageOuterContainer"}
                            generateSummary={this.getUserBuildings.bind(this)}
                            buildings={this.state.buildings}
                            buttonsVisible={true}
                            handleExport={this.exportData}
                        />
                    }

                    <div id="pageWrap" className="pageWrap" >

                        {this.state.windowWidth <= 650 &&
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                generateSummary={this.getUserBuildings.bind(this)}
                                buildings={this.state.buildings}
                                buttonsVisible={true}
                                handleExport={this.exportData}
                            />
                        }

                        <Row className="roomView-top-row">
                            <div className="roomSearchInnerContainer">
                                <Row className='roomView-inner-row' >
                                    <Col xs={4} className='roomView-inner-left-col'>
                                        <h3>{`${this.state.selectedRoom.locationName || this.state.roomSearch}`}</h3>
                                    </Col>
                                    <Col xs={8} className='roomView-inner-right-col'>
                                        <Form className="roomSearchForm2" onSubmit={this.handleRoomSearchButton.bind(this)}>
                                            <Form.Group style={{ width: 300, marginRight: 12 }}>
                                                <ReactSearchAutocomplete
                                                    id="roomSearchInput"
                                                    className="roomSearchInput"
                                                    items={this.state.buildingLocations}
                                                    fuseOptions={{ keys: ["locationName"] }}
                                                    resultStringKeyName="locationName"
                                                    onSearch={() => { }}//ignored
                                                    onHover={() => { }}//ignored
                                                    onSelect={(value) => {
                                                        this.handleRoomSearchInput(value);
                                                    }}
                                                    onFocus={() => { }}//ignored
                                                    autoFocus
                                                    maxResults={5}
                                                    showItemsOnFocus={false} 
                                                    showNoResultsText="No devices found"
                                                    formatResult={(item) => {return (<span>{Object.values(item.locationName)}</span>)}}
                                                />
                                            </Form.Group>
                                            <Button variant="primary" className="roomSearchButton2" type="submit">Change Location</Button>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                        </Row>
                        <Row className="roomView-middle-row">
                            <Col className="roomViewGraphBlock">
                                {/* <GraphBlock keys={this.state.dateKeys} gallonData={gallonData} wastedData={leakData} showModal={this.handleGraphShow} /> */}
                                <div className='BlockBackground'>
                                    <Tabs
                                        defaultActiveKey="gallons_graph"
                                        style={{ fontSize: 'small' }}
                                    >
                                        <Tab eventKey="gallons_graph" title="Water" style={{ height: '90%' }}>
                                            <span style={{ height: '100%' }}>
                                                <GraphBlock keys={this.state.dateKeys} gallonData={gallonData} wastedData={leakData} showModal={this.handleGraphShow} />
                                            </span>
                                        </Tab>

                                        <Tab eventKey="Temp_graph" title="Temp" style={{ height: '90%' }}>
                                            <span style={{ height: '100%' }}>
                                                <TempGraphBlock keys={this.state.THdataKeys} thData={this.state.THdata} label="Temperature" yaxis="temperature"/>
                                            </span>
                                        </Tab>

                                        <Tab eventKey="Hum_graph" title="Humidity" style={{ height: '90%' }}>
                                        <span style={{ height: '100%' }}>
                                                <HumidGraphBlock keys={this.state.THdataKeys} thData={this.state.THdata} label="Humidity" yaxis="humidity"/>
                                            </span>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </Col>
                        </Row>
                        <Row className="roomView-bottom-row">
                            <Col md={6} className="roomViewDetailBlock">
                                <div className='BlockBackground'>
                                    <Tabs
                                        defaultActiveKey="gallonsUsed/leaked"
                                        style={{ fontSize: 'small' }}
                                    >
                                        <Tab style={{ height: '90%' }} eventKey="gallonsUsed/leaked" title="Water">
                                            <span style={{ height: '45%' }}>
                                                <GallonsUsed gallonsUsed={this.state.totalGallons} />
                                            </span>
                                            <hr />
                                            <span style={{ height: '45%' }}>
                                                <GallonsLeaked gallonsLeaked={this.state.totalLeaked} />
                                            </span>
                                        </Tab>

                                        <Tab style={{ height: '90%' }} eventKey="Temp_max/min" title="Temp">
                                            <span style={{ height: '45%' }}>
                                                <DataBlock data={this.state.degreeScale == 'F' ? (Math.round(((MaxTemp * 9 / 5 + 32) * 100) / 100)) + "°F" : ((Math.round((MaxTemp * 100) / 100)) + "°C")} label="Max Temperature" />
                                            </span>
                                            <hr />
                                            <span style={{ height: '45%' }}>
                                                <DataBlock data={this.state.degreeScale == 'F' ? (Math.round(((MinTemp * 9 / 5 + 32) * 100) / 100)) + "°F" : (((Math.round(MinTemp * 100) / 100)) + "°C")} label="Min Temperature" />
                                            </span>
                                        </Tab>

                                        <Tab style={{ height: '90%' }} eventKey="Hum_max/min" title="Humidity">
                                            <span style={{ height: '45%' }}>
                                                <DataBlock data={MaxHum + "%"} label="Max Humidity" />
                                            </span>
                                            <hr />
                                            <span style={{ height: '45%' }}>
                                                <DataBlock data={MinHum + "%"} label="Min Humidity" />
                                            </span>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </Col>
                            <Col md={6} className="roomViewDetailBlock">
                                <div className='BlockBackground'>
                                    <Tabs
                                        defaultActiveKey="Alerts/Repairs"
                                        style={{ fontSize: 'small' }}
                                    >
                                        <Tab style={{ height: '90%' }} eventKey="Alerts/Repairs" title="Water">
                                            <span style={{ height: '45%' }}>
                                                <AlertsBlock alerts={this.state.alertCount} showAlertHistory={this.showAlertHistory} />
                                            </span>
                                            <hr />
                                            <span style={{ height: '45%' }}>
                                                <Flushes flushes={this.state.totalFlushes} />
                                            </span>
                                        </Tab>
                                        <Tab style={{ height: '90%' }} eventKey="Temp_Alerts/Avg" title="Temp">
                                            <span style={{ height: '45%' }}>
                                                <DataBlock data={this.state.THalerts} label="Alerts" />
                                            </span>
                                            <hr />
                                            <span style={{ height: '45%' }}>
                                                <DataBlock data={this.state.degreeScale == 'F' ? (Math.round(((AvgTemp * 9 / 5 + 32) * 100) / 100)) + "°F" : ((Math.round(AvgTemp * 100) / 100) + "°C")} label="Average Temperature" />
                                            </span>
                                        </Tab>
                                        <Tab style={{ height: '90%' }} eventKey="Hum_Alerts/Avg" title="Humidity">
                                            <span style={{ height: '45%' }}>
                                                <DataBlock data={this.state.THalerts} label="Alerts" />
                                            </span>
                                            <hr />
                                            <span style={{ height: '45%' }}>
                                                <DataBlock data={(Math.round((AvgHum * 100) / 100)) + "%"} label="Average Humidity" />
                                            </span>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </Col>
                            {/* <Col md={6} className="roomViewDetailBlock">
                                <GallonsUsed gallonsUsed={this.state.totalGallons} />
                            </Col>
                            <Col md={6} className="roomViewDetailBlock">
                                <GallonsLeaked gallonsLeaked={this.state.totalLeaked} />
                            </Col>
                            <Col md={6} className="roomViewDetailBlock">
                                <Flushes flushes={this.state.totalFlushes} />
                            </Col>
                            <Col md={6} className="roomViewDetailBlock">
                                <AlertsBlock alerts={this.state.alertCount} showAlertHistory={this.showAlertHistory} />
                            </Col> */}
                        </Row>

                        <Modal
                            show={this.state.showModal}
                            onHide={this.handleClose.bind(this)}
                            style={{ opacity: 1 }}
                            dialogClassName={this.state.windowWidth > 650 ? "modal-75w" : "modal-100W"}
                            animation={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Alert History</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {this.state.alertHistoryBlocks}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={this.handleClose.bind(this)}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.showGraphModal}
                            onHide={this.handleGraphClose.bind(this)}
                            style={{ opacity: 1 }}
                            dialogClassName="modal-100w"
                            animation={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Water Use</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <ExpandedGraphBlock keys={this.state.dateKeys} gallonData={gallonData} wastedData={leakData} history={this.props.history} />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={this.handleGraphClose.bind(this)}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>

                    </div>
                </div>
            );
        }
        else if (this.state.loaded) {
            Swal.close();
            return (
                <div id="pageOuterContainer" className="pageOuterContainer" >


                    {this.state.windowWidth > 650 &&
                        <Sidebar
                            pageWrapId={"pageWrap"}
                            outerContainerId={"pageOuterContainer"}
                            generateSummary={this.getUserBuildings.bind(this)}
                            buildings={this.state.buildings}
                            buttonsVisible={true}
                            handleExport={this.exportData}
                        />
                    }

                    <div id="pageWrap" className="pageWrap" >
                        {this.state.windowWidth <= 650 &&
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                generateSummary={this.getUserBuildings.bind(this)}
                                buildings={this.state.buildings}
                                buttonsVisible={true}
                                handleExport={this.exportData}
                            />
                        }
                        <Row className="roomViewSearch-top-row"> </Row>
                        <Row className="roomViewSearch-middle-row">
                            <Container className="roomSearchInnerContainer">
                                <Row xs={12} className="roomView-inner-top-row">
                                    <Col xs={12} style={{ "textAlign": "center" }}>
                                        <h2>Which location would you like to see?</h2>
                                    </Col>
                                </Row>
                                <Row xs={12} className="roomView-inner-bottom-row">
                                    <Col xs={12}>
                                        <Container className="roomSearchFormContainer">
                                            <Form className="roomSearchForm" onSubmit={this.handleRoomSearchButton.bind(this)}>
                                                <Form.Group style={{ width: 300, marginBottom: 10 }}>
                                                    <ReactSearchAutocomplete
                                                        id="roomSearchInput"
                                                        className="roomSearchInput"
                                                        items={this.state.buildingLocations}
                                                        fuseOptions={{ keys: ["locationName"] }}
                                                        resultStringKeyName="locationName"
                                                        onSearch={() => { }}//ignored
                                                        onHover={() => { }}//ignored
                                                        onSelect={(value) => {
                                                            this.handleRoomSearchInput(value);
                                                        }}
                                                        onFocus={() => { }}//ignored
                                                        autoFocus
                                                        maxResults={5}
                                                        showItemsOnFocus={true}
                                                        showNoResultsText="No devices found"
                                                        formatResult={(item) => {return (<span>{Object.values(item.locationName)}</span>)}}
                                                    />
                                                </Form.Group>
                                                <Button variant="primary" className="roomSearchButton" type="submit">See Location</Button>
                                            </Form>
                                        </Container>
                                    </Col>
                                </Row>
                            </Container>
                        </Row>
                        <Row className="roomViewSearch-bottom-row"> </Row>
                    </div>
                </div>
            );
        }
        else {
            return (
                <LoadingView />
            );
        }
    }
}

export default withRouter(RoomView);


