import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import Container from 'react-bootstrap/Container';
import config from '../../../utilities/config.js';
import AdvSch from './AdvancedSeachVars';
import { Table, Row, Col, Dropdown } from 'react-bootstrap';
import LoadingView from '../../LoadingView/LoadingView.js';

var info = null;
var orgname = null;
var locationInfo = null;

class LocationResultsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadedBuilding: false,
            loadedProp: false,
            loadedOrg: false,
            loadedLocs: false,
        };
    }
    componentDidMount() {
        this.getInfo()
    }
    //get information based on filled out fields
    getInfo() {
        info = []
        info.push(
            <tr key={AdvSch.Building.id}>
                <td>{"Building: " + AdvSch.Building.name}</td>
            </tr>);
        //Get Info
        let url = `${config.api}property/${AdvSch.Building.propID}`;
        let token = localStorage.getItem("mlToken");

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        )
            .then((response) => {
                this.setState({
                    loadedProp: true,
                });
                //console.log(response)
                const data = response["data"];
                
                orgname = data.organizationID
                info.push(
                    <tr key={data.id}>
                        <td>{"Property: " + data.label}</td>
                    </tr>);
                url = `${config.api}organization/${orgname}`;
                token = localStorage.getItem("mlToken");

                axios.get(
                    url,
                    {
                        headers: { Authorization: `Bearer ${token}` }
                    }
                )
                    .then((response) => {
                        this.setState({
                            loadedOrg: true,
                        });
                        const data = response["data"];
                        info.push(
                            <tr key={data.id}>
                                <td>{"Organization: " + data.label}</td>
                            </tr>);

                        return info;
                    },
                        (error) => {
                            //console.log(error);
                        }
                    );



            },
                (error) => {
                    //console.log(error);
                }
            );



    }
    getLoc() {
        //Get all Locations
        if (this.state.loadedLocs) {
            return locationInfo;
        }
        else {
            let url = `${config.api}locations/${AdvSch.Locs.id}`;
            let token = localStorage.getItem("mlToken");

            axios.get(
                url,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            )
                .then((response) => {
                    ////console.log(response)
                    const data = response["data"][0];
                    //console.log("data",data)
                    locationInfo = <tbody>
                            <tr key={data.id}>
                                <td><strong>Name:</strong>{data.locationLabel}</td>
                            </tr>
                            <tr key={data.id + 1}>
                                <td><strong>Level:</strong>{data.level}</td>
                            </tr>
                            <tr key={data.id + 2}>
                                <td><strong>Device:</strong>{data.deviceLabel}</td>
                            </tr>
                        </tbody>;
                    ////console.log(locationInfo)
                    this.setState({
                        loadedLocs: true,
                    });
                    return locationInfo;
                },
                    (error) => {
                        //console.log(error);
                    }
                );
        }
    }





    render() {
        if (this.state.loadedProp && this.state.loadedOrg) {
            Swal.close();
            //console.log("Info",info);
            return (<Container>
                <h1>
                    <Table>
                        <tbody>
                            {info}
                        </tbody>
                    </Table>
                </h1>
                <Row>
                    <Col>
                        <h2>Location:</h2>
                        <Table>
                                {this.getLoc()}
                        </Table>
                    </Col>
                </Row>
            </Container>)
        }
        else {
            return (
                <div>
                    <LoadingView />
                </div>
            );
        }
    }

}
export default withRouter(LocationResultsView);