import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import Swal from 'sweetalert2';
import Reaptcha from 'reaptcha';
import './ChangePassword.scss';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import config from '../../utilities/config';
import Col from 'react-bootstrap/Col';
import { Row } from 'react-bootstrap';



class ChangePassword extends Component{
    


    constructor(props) {
        super(props);
      }


    handleSubmit = (event) => {
        event.preventDefault();  
			  
        const url = `${config.api}generate-hash`;


       let p0 = $("#password").val();
       let p1 = $("#password1").val();
       //console.log("PASSWORDS");
       //console.log(p0);
       //console.log(p1);
       if (p0 != p1){
           Swal.fire({
               'title' : "Passwords do not match"
           });
           return;
       }

        const password = p0;
        const setPassword = 1;
        Swal.fire({
            'title' : 'Resetting Password',
            'timer' : 60000,
            'allowOutsideClick': false,
            onBeforeOpen: () => {
                Swal.showLoading();
            }
        
        
        });

        axios.post(url , {token : config.token , password: password , userID: config.userID}).then(res => {
            
            Swal.fire({
                'icon': "success",
                'title': "Password succesfully changed."
            }).then(function(){ 
                // window.location.href = "https://portal.flowactive.com/login";
             });


        });
        
    }

     getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    

    render(){
        return (

            <div>
                <div style={{'paddingTop': '20px', 'textAlign': 'center'}}>
                    <Button className="settings-logout" variant="outline-danger" href="/logout" >Logout</Button>
                </div>
                <hr />
                <h3 style={{'paddingTop': '20px', 'paddingLeft': '30px'}}>Change your password</h3>
                <div style={{'width': '75%', 'paddingLeft': '20vw'}}>
                    <Form style={{'paddingTop': '10px'}} onSubmit={this.handleSubmit} id="changePasswordForm">
                        <Row>
                            <Col xs={12}>
                                <Form.Group>
                                    <Form.Label className="fullWidth"><h4>Current Password</h4></Form.Label>
                                    <Form.Text className="fullWidth">Please enter the password you currently use to log in to your account.</Form.Text>
                                    <Form.Control className="changePasswordInput" type="password" name="current-password" id="current-password" placeholder="" onChange={this.handleChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col xs={12}>
                                <Form.Group>
                                    <Form.Label className="fullWidth"><h4>New Password</h4></Form.Label>
                                    <Form.Text className="fullWidth">Please enter the new password you want to use.</Form.Text>
                                    <Form.Control className="changePasswordInput" type="password" name="password" id="password" placeholder="" onChange={this.handleChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col xs={12}>
                                <Form.Group>
                                    <Form.Label className="fullWidth"><h4>Confirm New Password</h4></Form.Label>
                                    <Form.Text className="fullWidth">Please enter the new password you want to use again.</Form.Text>
                                    <Form.Control className="changePasswordInput" type="password" name="password1" id="password1" placeholder="" onChange={this.handleChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div style={{'textAlign': 'center', 'paddingTop': '30px'}}>
                            <Button className="changePasswordButton" variant="primary" type="submit">Submit</Button>
                        </div>
                    </Form>
                </div>
            </div>
        );

    }

}


export default withRouter(ChangePassword);